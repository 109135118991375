<template>
  <div class="app-mall-confirm container">
    <div class="content ex padding-20-0">
      <el-steps :active="1" process-status="success" simple>
        <el-step title="我的购物车" />
        <el-step title="填写核对订单信息" />
        <el-step title="成功提交订单" />
      </el-steps>
      <div class="padding-20-0 fs-large">请填写并核对以下订单信息:</div>
      <form-view ref="form" mode="mall" @completed="handleCompleted" v-if="show"></form-view>
    </div>
  </div>
</template>

<script>
import formView from "../../purchase/order/form-view";

export default {
  components: { formView },
  props: {
    supplier: String | Number
  },
  watch: {
    supplier: "redirect"
  },
  data() {
    return {
      show: false
    };
  },
  methods: {
    generateForm() {
      let co = JSON.parse(window.localStorage.getItem("cart-confirm"));
      if ((co.goods && co.goods.length) || (co.groups && co.groups.length)) {
        (co.goods || []).forEach(o => {
          o.businessId = this.$uuid();
          o.promotionId = null;
          o.singleGoodsGroupId = null;
          o.singleGoodsLadderId = null;
          o.promotionName = null;
          o.promotionJoinType = null;
          o._promotions = [];
        });

        this.$refs.form &&
          this.$refs.form.resetForm(null, null, {
            sellerId: this.supplier,
            items: co.goods,
            _groupSales: co.groups
          });
      } else {
        this.redirect();
      }
    },
    handleCompleted(res) {
      if (String(this.$route.query.mode) !== "1") window.mall.cart.clear(false);
      window.localStorage.setItem("cart-complete-order", JSON.stringify(res));
      window.localStorage.removeItem("cart-confirm");
      this.$router.replace({
        path: "/mall/cart/complete/" + res.id
      });
    },
    redirect() {
      this.$router.replace("/mall");
    }
  },
  activated() {
    this.show = true;
    this.$nextTick(_ => {
      this.generateForm();
    });
  },
  deactivated() {
    this.show = false;
  }
};
</script>